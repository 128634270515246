@use 'styles/base/mixins';

.footer {
  margin-top: var(--spacing-20);
  padding-top: var(--spacing-08);
  color: var(--white);
  background-color: var(--black);

  @include mixins.grid;
}

.text {
  margin-bottom: var(--spacing-10);
  padding-bottom: var(--spacing-10);
  border-bottom: 2px solid rgb(116 118 124 / 20%);

  div {
    margin-bottom: 16px;

    @include mixins.breakpoint(l) {
      margin-bottom: 24px;
    }
  }

  p,
  div {
    color: var(--gray-dark);
    font-size: 11px;
    line-height: 2;
    text-align: justify;
  }
}

.homeLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-05);
  padding-bottom: var(--spacing-05);
  border-bottom: 2px solid rgba(116 118 124 / 20%);

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

@include mixins.breakpoint(xs-only) {
  .mainContentZone {
    padding: 0 var(--spacing-06) var(--spacing-06);
  }
}
