.button {
  display: flex;
  gap: var(--spacing-02);
  align-items: center;
  color: var(--gray-dark);
  background: transparent;
  cursor: pointer;
  border: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }
}
