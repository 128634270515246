@use 'styles/base/mixins';

.footer {
  margin-top: var(--spacing-20);
  color: var(--white);
  background-color: var(--black);
}

.inner {
  width: 100%;
  padding: var(--spacing-04) var(--spacing-04) var(--spacing-08);

  @include mixins.devices(desktop-only) {
    max-width: 800px;
  }

  @include mixins.devices(desktopXl) {
    width: 66.67%;
    max-width: 1200px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }
}

.logo {
  margin: var(--spacing-05) 0 var(--spacing-10) 0;
}

.text {
  margin-bottom: var(--spacing-10);
  padding-bottom: var(--spacing-10);
  border-bottom: 2px solid rgb(116 118 124 / 20%);

  div {
    margin-bottom: 16px;

    @include mixins.devices(tablet) {
      margin-bottom: 24px;
    }
  }

  p,
  div {
    color: var(--gray-dark);
    font-size: 11px;
    line-height: 2;
    text-align: justify;
  }
}

.homeLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-05);
  padding-bottom: var(--spacing-05);
  border-bottom: 2px solid rgba(116 118 124 / 20%);

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
